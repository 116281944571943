<template>
  <QuoteEditView disabled />
</template>

<script>
import QuoteEditView from '@/views/quote/QuoteEditView'

export default {
  name: 'QuoteView',
  components: {
    QuoteEditView
  }
}
</script>
